<template>
  <div class="col-sm-9 mt-3" style="margin: auto">
    <h4 class="text-primary mt-2 mb-4">Update Picture</h4>
     <UploadUserPic />
    <div class="text-center mx-0 mt-2 action-area">
      <CButton
        type="button"
        name="registration-home"
        class="btn rounded-0 px-5 btn-lg m-1 mt-3 float-left btn-outline-primary btn-lg mr-2"
        @click="navToCandidateList"
        >Home</CButton
      >
      <CButton
        class="px-5 m-1 btn-outline-primary rounded-0 mt-3 float-right primary btn-lg"
        @click="goingBack()"
        >Back</CButton
      >
    </div>
  </div>
</template>
<script>
import UploadUserPic from "@/containers/UploadOrgLogos/UploadUserPic";
import UploadOrgPictures from "@/containers/UploadOrgLogos/UploadOrgPictures";
import { mapGetters } from "vuex";
export default {
  name: "UploadOrganisationPictures",
  components: {
    UploadUserPic,
    UploadOrgPictures,
  },
  data() {
    return {
      activeTab: 0,
      isImgUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgID"]),
  },
  methods: {
    updateTab(value) {
      this.activeTab = value;
    },
    updateValueChange(value) {
      this.isImgUpdated = value;
    },
    navToCandidateList() {
      this.$router.push({
        path: "/candidate-list?active=%5Btrue,null%5D&deleted=%5Bfalse%5D",
      });
    },
    goingBack() {
      this.$router.go(-1);
    },
  },
};
</script>
